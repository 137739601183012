const storageKey = 'av:k'
export function storageName() {
  return localStorage.getItem(storageKey)
}

export function setStorageName(email, appId) {
  const parts = email.split('@')
  const username = parts[0]
  const key = `${appId}:${username}`
  localStorage.setItem(storageKey, key)
}

export function deleteStorageName() {
  localStorage.removeItem(storageKey)
}
