export default {
  jalisco: [
    {
      id: '7c4d93edcba8d',
      name: 'Belenes',
      city: 'Zapopan',
      state: 'Jalisco',
      avatar: 'site-belenes.jpg',
      she: 'georgina.quintero@henkel.com'
    },
    {
      id: '7aeaf51e18c26',
      name: 'Cedis',
      city: 'Guadalajara',
      state: 'Jalisco',
      avatar: 'site-cedis-gdl.jpg',
      she: 'alejandra.ramos@henkel.com'
    },
    {
      id: '4f68e9e3cc9c2',
      name: 'Natco',
      city: 'Zapopan',
      state: 'Jalisco',
      avatar: 'site-natco.jpg',
      she: 'francisco.barahona@henkel.com'
    },
    {
      id: 'ba8e071356c93',
      name: 'Tesistán',
      city: 'Zapopan',
      state: 'Jalisco',
      avatar: 'site-tesistan.jpg',
      she: 'lizbeth.hernandez@henkel.com'
    }
  ]
}
