export default {
  universe: 2000,
  items: [
    {
      requirementId: 'f3de0bb502f0e',
      verified: 1300
    },
    {
      requirementId: '3256f8b1a310f',
      verified: 400
    },
    {
      requirementId: '25800fc81138',
      verified: 1600
    }
  ]
}
