export function stageWorkflow(item) {
  return workflowMap[item.workflow_id]
}

export function authWorkflow(type) {
  return authWorkflowMap[type]
}

const authWorkflowMap = {
  request: 'auth',
  request_doc: 'auth_doc',
  auth: 'auth',
  auth_doc: 'auth_doc'
}

const workflowMap = {
  eoa: 'employee_one_access',
  eca: 'employee_continuos_access',
  poa: 'provider_one_access',
  pca: 'provider_continuos_access',
  efa: 'employee_files_access',
  pfa: 'provider_files_access'
}
