import defaultDescriptions from '@/store/default/descriptions/requirements'
import defaultStats from '@/store/default/requirements-stats'

export const namespaced = true

const descriptions = defaultDescriptions
const stats = defaultStats
const orders = []
const requirementSetKey = 'covid19pass'

export const state = {
  descriptions,
  deliveries: [],
  fieldsAccepted: true,
  requirement: {
    supervisorEmail: null,
    resources: [
      {
        requirementId: 'f3de0bb502f0e',
        resourceId: '150c633dedd01',
        name: 'Todo sobre la prevención del COVID-19',
        valid: false
      },
      {
        requirementId: '3256f8b1a310f',
        resourceId: '90eed4cb91641',
        name:
          'Recomendaciones para un retorno seguro al trabajo ante COVID -19',
        valid: false
      },
      {
        requirementId: '25800fc81138',
        resourceId: '943746ba643b1',
        name: 'Un regreso seguro a Henkel ante el Covid-19',
        valid: false
      }
    ]
  },
  stats,
  orders,
  overlay: false
}

export const getters = {
  isVisibleOverlay(state) {
    return state.overlay
  },
  allFilesFulfilled(state) {
    if (!state.deliveries.length) return false
    const res = state.descriptions[requirementSetKey].reduce(
      (fulfilled, descn) =>
        fulfilled && !!state.deliveries.find(d => d.id === descn.id),
      true
    )
    return res
  },
  allFieldsFulfilled(state) {
    return state.fieldsAccepted
  },
  allRequirementsFulfilled(state, getters) {
    return getters.allFilesFulfilled && state.fieldsAccepted
  },
  getAllStats(state) {
    return state.stats.items
  },
  statsById(state, getters) {
    return requirementId =>
      getters.getAllStats.find(item => item.requirementId == requirementId)
  },
  getStatsUniverse(state) {
    return state.stats.universe
  },
  requirementById(state) {
    return id =>
      state.descriptions[requirementSetKey].find(
        description => description.id === id
      )
  },
  fileById(state) {
    return id => {
      return state.deliveries.find(delivery => delivery.id === id)
    }
  },
  fileSaved(state, getters) {
    return id => {
      return !!getters.fileById(id)
    }
  }
}

export const mutations = {
  SET_FULFILL_REQUIREMENT(state, requirement) {
    state.deliveries.push(requirement)
  },
  UPDATE_FULFILL_REQUIREMENT(state, requirement) {
    const index = state.deliveries.findIndex(
      delivery => delivery.id === requirement.id
    )
    if (index >= 0) state.deliveries.splice(index, 1, requirement)
  },
  SET_FIELDS_ACCEPTED(state, condition) {
    state.fieldsAccepted = condition
  },
  CHANGE_STATUS(
    state,
    {
      status,
      fromRequirements,
      toRequirements,
      fromRequirementIndex,
      toRequirementIndex
    }
  ) {
    const requirementToMove = fromRequirements[status].splice(
      fromRequirementIndex,
      1
    )[0]
    toRequirements[status].splice(toRequirementIndex, 0, requirementToMove)
  },
  SHOW_OVERLAY(state) {
    state.overlay = true
  },
  HIDE_OVERLAY(state) {
    state.overlay = false
  }
}

export const actions = {
  fulfillRequirement({ commit, getters }, requirement) {
    const req = {
      id: requirement.id,
      name: requirement.file.name,
      size: requirement.file.size
    }
    const saved = getters.fileSaved(req.id)
    if (requirement['file'] && !saved) {
      commit('SET_FULFILL_REQUIREMENT', req)
    } else if (requirement['file'] && saved) {
      commit('UPDATE_FULFILL_REQUIREMENT', req)
    }
  },
  setFieldsAccepted({ commit }, condition) {
    commit('SET_FIELDS_ACCEPTED', condition)
  },
  setActive({ commit }, { from, to, fromIndex, toIndex }) {
    commit('CHANGE_STATUS', { status: 'active', from, to, fromIndex, toIndex })
  },
  setInactive({ commit }, { from, to, fromIndex, toIndex }) {
    commit('CHANGE_STATUS', {
      status: 'inactive',
      from,
      to,
      fromIndex,
      toIndex
    })
  },
  showOverlay({ commit }) {
    commit('SHOW_OVERLAY')
  },
  hideOverlay({ commit }) {
    commit('HIDE_OVERLAY')
  }
}
