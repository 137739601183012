/**
 * Get the privileged control path
 */
export function getControlPath(rs, ou, aid, topic, status) {
  return `requests/${rs}/${ou}/${aid}/${topic}/${status}/control`
}

/**
 * The path to read the contents
 */
export function getQueryPath(rs, ou, aid, topic, status) {
  return `requests/${rs}/${ou}/${aid}/${topic}/${status}/query`
}

/**
 * `requests/${rs}/${ou}/${aid}/${topic}/${status}/meta`
 *  ---- .../meta/covid19pass
 *  ---- .../meta/covid19requirements
 *  ---- .../meta/accessProvidersPass
 *  ---- .../meta/accessProvidersRequirements
 */
// function getRequestsMetaPath(rs, ou, aid, uid, topic) {
//   return `requests/${rs}/${ou}/${aid}/${topic}/${status}/meta`
// }
