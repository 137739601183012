import defaultState from '@/store/default/descriptions/sites'

export const namespaced = true

const countryState = 'jalisco'

export const state = {
  sitesOrgs: [
    { siteId: 'ba8e071356c93', ou: 'a' },
    { siteId: '7c4d93edcba8d', ou: 'b' },
    { siteId: '7aeaf51e18c26', ou: 'c' },
    { siteId: '4f68e9e3cc9c2', ou: 'd' }
  ]
}

export const getters = {
  getAllActiveSites(state) {
    return state.sites[countryState]
  },
  siteById(state) {
    return siteId => state.sites[countryState].find(s => s.id === siteId)
  },
  ouBySite(state) {
    return siteId => state.sitesOrgs.find(s => s.siteId === siteId).ou
  }
}

export const mutations = {
  INIT(state) {
    state.sites = defaultState
  }
}

export const actions = {
  setupSites({ commit }) {
    commit('INIT')
  }
}
