export default {
  covid19pass: [
    {
      id: 'f3de0bb502f0e',
      resourceId: '150c633dedd01',
      verifiedBy: 'app',
      verificationRequired: true,
      reference: {
        sizeDiff: 0.000018,
        createdModifiedDiff: 0,
        attrsDiff: 0,
        dimenssionsDiff: 0,
        imageDiff: '<.07',
        name: 'Constancia TSPC.pdf',
        size: 1703381
      }
    },
    {
      id: '3256f8b1a310f',
      resourceId: '90eed4cb91641',
      verifiedBy: 'app',
      verificationRequired: true,
      reference: {
        sizeDiff: 0.0018,
        createdModifiedDiff: 0,
        attrsDiff: 0,
        dimenssionsDiff: 0,
        imageDiff: '<.07',
        name: 'Constancia RPURSATAC.pdf',
        size: 1703402
      }
    }
    // {
    //   id: '25800fc81138',
    //   resourceId: '943746ba643b1',
    //   verifiedBy: 'app',
    //   verificationRequired: false,
    //   reference: {
    //     sizeDiff: 0.000002,
    //     createdModifiedDiff: 0,
    //     attrsDiff: 0,
    //     dimenssionsDiff: 0,
    //     imageDiff: '<.07',
    //     name: 'Constancia Henkel.pdf',
    //     size: 1703402
    //   }
    // }
  ]
}
