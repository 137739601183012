export default [
  {
    id: 'app',
    role_name: 'App',
    area_name: 'La organización'
  },
  {
    id: 'requestor',
    role_name: 'Solicitante',
    area_name: 'La organización'
  },
  {
    id: 'head',
    role_name: 'Jefe',
    area_name: 'Jefe de área'
  },
  {
    id: 'door_security',
    role_name: 'Vigilante',
    area_name: 'Vigilancia'
  },
  {
    id: 'head_security',
    role_name: 'Jefe de Seguridad',
    area_name: 'Seguridad'
  },
  {
    id: 'health',
    role_name: 'Médico',
    area_name: 'Salud'
  },
  {
    id: 'monitor',
    role_name: 'Monitor',
    area_name: 'Monitor'
  }
]
