export default {
  auth: [
    {
      id: 'auth1',
      next: 'auth2',
      role: 'head',
      headline: 'Solicitud de accesso',
      role_descn: 'como Jéfe inmediato',
      subtitle: {
        approve: '¿Aceptas la solicitud de acceso?',
        reject: '¿Rechazas la solicitud de accesso?'
      },
      btn: {
        approve: 'Si autorizo',
        reject: 'No autorizo'
      }
    },
    {
      id: 'auth2',
      next: 'auth3',
      role: 'head_security',
      headline: 'Autorización de ingreso',
      role_descn: 'como Jefe de Seguridad',
      subtitle: {
        approve: '¿Autorizas el ingreso?',
        reject: '¿Declinas el ingreso?'
      },
      btn: {
        approve: 'Si autorizo',
        reject: 'No autorizo'
      }
    },
    {
      id: 'auth3',
      next: 'end',
      role: 'health',
      headline: 'Revisión médica',
      role_descn: 'como Médico en turno',
      subtitle: {
        approve: 'Sin posibles sínotmas.',
        reject: 'El solicitante presenta posibles síntomas.'
      },
      btn: {
        approve: 'Entrego pase',
        reject: 'Revoco pase'
      }
    }
  ],
  auth_doc: [
    {
      id: 'auth1',
      next: 'end',
      role: 'monitor',
      headline: 'Revisión de documento',
      role_descn: 'como Monitor',
      subtitle: {
        approve: '¿El documento es válido?',
        reject: '¿No es un documento válido?'
      },
      btn: {
        approve: 'Aprobar',
        reject: 'Rechazar'
      }
    }
  ]
}
