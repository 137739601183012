import defaultState from '@/store/default/routes'

export const namespaced = true

export const state = {
  routes: []
}

export const getters = {
  routes(state) {
    return state.routes
  }
}

export const mutations = {
  INIT(state) {
    state.routes = defaultState
  },
  ENABLE_ROUTE(state, name) {
    const index = state.routes.findIndex(route => route.to === name)
    if (index >= 0) state.routes[index].disabled = false
  },
  DISABLE_ROUTE(state, name) {
    const index = state.routes.findIndex(route => route.to === name)
    if (index >= 0) state.routes[index].disabled = true
  }
}

export const actions = {
  setupRoutes({ commit }) {
    commit('INIT')
  },

  enableRoute({ commit }, name) {
    commit('ENABLE_ROUTE', name)
  },

  disableRoute({ commit }, name) {
    commit('DISABLE_ROUTE', name)
  }
}
